<template>
    <div >
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
         <v-toolbar flat dense class="tc-title">
             <router-link to="student-extra-curricular" class="nav-arrow-left"><v-icon class="left-arrow" >mdi-arrow-left-bold</v-icon></router-link>
            <v-toolbar-title>Publication Details</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="logimx-2" fab small  @click="dialog_add=true"><v-icon>mdi-plus</v-icon></v-btn>
             <router-link to="student-social" class="nav-arrow-right"><v-icon class="right-arrow">mdi-forward</v-icon></router-link>
         </v-toolbar>
        <!--<template>
            <v-toolbar flat dense color="white">
                 <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details>
                    </v-text-field>
                <v-spacer></v-spacer>
                <span style="width: 20px;"></span>
                <template>
                    <v-btn color="primary" dark class="mb-2" @click="dialog_add=true"><v-icon>mdi-plus</v-icon> Publication</v-btn>
                </template>
            </v-toolbar>
        </template>
        <template>
            <v-data-table :headers="headers" :items="learner_publication_list" class="elevation-1" :search="search">
                <template v-slot:item.script="{ item }">
                    <span v-if="item.manuscript=='NA'">
                    {{ item.manuscript}}
                    </span>
                    <span v-else>
                        <a v-bind:href="item.manuscript" target="blank" class="alink"><v-icon color="success" >mdi-cloud-download</v-icon></a>
                    </span>
                </template>
                
                <template v-slot:item.edit="{ item }">
                    <v-icon color="indigo" @click="editItem(item)">mdi-pencil-circle</v-icon>
                </template>
                <template v-slot:item.delete="{ item }">
                    <v-icon color="red" @click="delId(item)">mdi-delete-circle</v-icon>
                </template>
            </v-data-table>
        </template>-->
        <v-row>
            <v-col cols="12" sm='5' v-for="item in learner_publication_list" :key="item">
                <v-card style=" margin: 8px; height:100%">
                    <v-card-title class="c-title">
                        {{item.title}}
                    </v-card-title>
                    <v-card-text>
                        <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                
                                <span class="lbl" >Name Of Publication</span>
                            </v-col>
                            <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                                : {{item.name_of_conference_journal}}
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Level</span>
                            </v-col>
                            <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                                : {{item.publicationlevel}}
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Category</span>
                            </v-col>
                            <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                                : {{item.publicationcategory}}
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Publication Date</span>
                            </v-col>
                            <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                                : {{item.date_of_publication}}
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">ISBN No.</span>
                            </v-col>
                            <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                                : {{item.isbn_no}}
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Publication Link</span>
                            </v-col>
                            <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important" >
                                : {{item.publication_link}}
                                
                            </v-col>
                        </v-row>
                        <v-row style="margin: 0px !important; padding: 0px !important">
                            <v-col cols="6" sm="4" style="margin: 0px !important; padding: 0px !important">
                                <span class="lbl">Manuscript</span>
                            </v-col>
                             <v-col cols="6" md="6" v-if="item.manuscript=='NA'" style="margin: 0px !important; padding: 0px !important">
                                : {{item.manuscript}}
                            </v-col>
                            <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important">
                                : <a v-bind:href="item.manuscript" target="blank" class="alink">{{item.filename}}</a>
                            </v-col>
                        </v-row>
                        
                    </v-card-text>
                    <v-card-actions>
                                <v-icon color="indigo" @click="editItem(item)">mdi-pencil-circle</v-icon>
                                <v-spacer></v-spacer>
                                <v-icon color="red" @click="delId(item)">mdi-delete-circle</v-icon>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_add" persistent max-width="550px">
                    <v-card>
                        <v-card-title class="card-title">
                            Add Publication
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <label>Tittle<span class="required">*</span> :</label>
                                        <v-text-field placeholder="Enter Tittle" v-model="savedata.ptittle" :rules="[v => !!v || 'Required']"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <label>Name Of Publication<span class="required">*</span> :</label>
                                        <v-text-field placeholder="Enter Name Of Publication" v-model="savedata.publicationname" :rules="[v => !!v || 'Required']"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <label>Date<span class="required">*</span> :</label>
                                        <input type="date" placeholder="Select Deate" v-model="savedata.pdate" /> 
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <label>Publication Category<span class="required">*</span> :</label>
                                        <v-select :items="publication_category_list" v-model="savedata.pcategory" item-text="name" item-value="id" required :rules="[v => !!v || 'Required']"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <label>Publication Level<span class="required">*</span> :</label>
                                        <v-select :items="publication_level_list" v-model="savedata.publicationlevel" item-text="name" item-value="id" required :rules="[v => !!v || 'Required']"></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <label>Publication Online Link<span class="required"></span> :</label>
                                        <v-text-field placeholder="Enter Publication Online Link" v-model="savedata.link"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <label>ISBN No.<span class="required"></span> :</label>
                                        <v-text-field placeholder="Enter ISBN No." v-model="savedata.isbn"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <label>Manuscript File<span class="required"></span> :</label>
                                        <v-file-input type="file" placeholder="Select Manuscript" prepend-icon="mdi-file" ref="file" show-size v-model="savedata.file"></v-file-input>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <!-- <v-btn color="blue darken-1" text @click="dialog_add= false">Close</v-btn>
                            <v-btn color="blue darken-1" text @click="save()">Save</v-btn> -->
                             <v-btn color="primary darken-1"  outlined text @click="dialog_add= false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="save()">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_edit" persistent max-width="650px">
                    <v-card>
                        <v-card-title class="card-title">
                            Edit Publication
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <label>Tittle<span class="required">*</span> :</label>
                                        <v-text-field placeholder="Enter Tittle" v-model="editedItem.ptittle"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <label>Name Of Publication<span class="required">*</span> :</label>
                                        <v-text-field placeholder="Enter Name Of Publication" v-model="editedItem.publicationname"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6"> 
                                        <label>Date<span class="required">*</span> :</label>
                                        <input type="date" placeholder="Select Deate" v-model="editedItem.pdate" /> 
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <label>Publication Category<span class="required">*</span> :</label>
                                        <v-select :items="publication_category_list" v-model="editedItem.pcategory" item-text="name" item-value="id" required></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <label>Publication Level<span class="required">*</span> :</label>
                                        <v-select :items="publication_level_list" v-model="editedItem.publicationlevel" item-text="name" item-value="id" required></v-select>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <label>Publication Online Link<span class="required"></span> :</label>
                                        <v-text-field placeholder="Enter Publication Online Link" v-model="editedItem.link"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <label>ISBN No.<span class="required"></span> :</label>
                                        <v-text-field placeholder="Enter ISBN No." v-model="editedItem.isbn"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <label>Manuscript File<span class="required"></span> :</label>
                                        <v-file-input type="file" placeholder="Select Manuscript" prepend-icon="mdi-file" ref="file" show-size v-model="editedItem.file"></v-file-input>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <!-- <v-btn color="blue darken-1" text @click="dialog_edit= false">Close</v-btn>
                            <v-btn color="blue darken-1" text @click="edit()">Update</v-btn> -->
                             <v-btn color="primary darken-1"  outlined text @click="dialog_edit= false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="edit()">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_delete" persistent max-width="290">
                    <v-card>
                        <v-card-title class="del-card-title">Record Delete</v-card-title>
                        <input type="hidden" v-model="deleteItem.academic_id" />
                        <v-card-text>
                            Are you sure you want Delete this Record?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="dialog_delete = false">No</v-btn>
                            <v-btn color="red darken-1" text @click="deletedata()">Yes</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <template>
            <div class="text-center">
                <v-dialog v-model="dialog_upload" hide-overlay persistent width="300">
                    <v-card color="primary" dark>
                        <v-card-text>
                            Please wait File uploding...
                            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
        </template>
    </div>
</template>
<script>
    import axios from "axios";
    import StudentProfile from "./StudentProfile";
    import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
    import * as lang from "vuejs-datepicker/src/locale";
    import datetime from "vuejs-datetimepicker";
    export default {
        components: {
            StudentProfile,
            Datepicker,
        },
        data: () => ({
            dialog_upload: false,
            savedata: {
                file: null,
                publicationname: null,
                ptittle: null,
                pdate: null,
                publicationlevel: null,
                pcategory: null,
                isbn: null,
                link: null,
            },
            editedItem: {
                file: null,
                publicationname: null,
                ptittle: null,
                pdate: null,
                publicationlevel: null,
                pcategory: null,
                isbn: null,
                link: null,
                id: null,
            },
            publication_category_list: "",
            publication_level_list: "",
            dialog_add: false,
            dialog_edit: false,
            dialog_delete: false,
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search: "",
            learner_publication_list: [],

            headers: [
                {
                    text: "Sr. No.",
                    align: "left",
                    sortable: false,
                    value: "id",
                },
                {
                    text: "Title",
                    align: "left",
                    sortable: false,
                    value: "title",
                },
                {
                    text: "Name Of Publication",
                    align: "left",
                    sortable: false,
                    value: "name_of_conference_journal",
                },
                {
                    text: "Level",
                    align: "left",
                    sortable: false,
                    value: "publicationlevel",
                },
                {
                    text: "Category",
                    align: "left",
                    sortable: false,
                    value: "publicationcategory",
                },
                {
                    text: "Publication Date",
                    align: "left",
                    sortable: false,
                    value: "date_of_publication",
                },
                {
                    text: "ISBN No.",
                    align: "left",
                    sortable: false,
                    value: "isbn_no",
                },
                {
                    text: "Publication Link",
                    align: "left",
                    sortable: false,
                    value: "publication_link",
                },
                {
                    text: "Manuscript",
                    align: "left",
                    sortable: false,
                    value: "script",
                },
               
                {
                    text: "Edit",
                    value: "edit",
                    sortable: false,
                },
                {
                    text: "Delete",
                    value: "delete",
                    sortable: false,
                },
            ],

            deleteItem: {
                lactivity_id: null,
            },
            editAllData: [],
        }),
        watch: {
            dialog(val) {
                val || this.close();
            },
        },
        mounted() {
            this.onLoad();
        },
        methods: {
            onLoad() {
                axios
                    .post("/LearnerData/learnerPublication")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.learner_publication_list = res.data.learner_publication_list;
                            this.publication_category_list = res.data.publication_category_list;
                            this.publication_level_list = res.data.publication_level_list;
                        } else {
                            this.showSnackbar("#b71c1c", "No Data");
                            //window.console.log(res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                       // window.console.log(error);
                    })
                    .finally(() => {
                        // 'use strict';
                    });
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            editItem(item) {
                //alert("ID "+item.publicationcategoryid)
                this.dialog_edit = true;
                this.editedItem.id = item.idd;
                this.editedItem.ptittle = item.title;
                this.editedItem.publicationname = item.name_of_conference_journal;
                this.editedItem.pdate = item.date_of_publication;
                this.editedItem.link = item.publication_link;
                this.editedItem.isbn = item.isbn_no;
                this.editedItem.publicationlevel = item.publicationlevelid;
                this.editedItem.pcategory = item.publicationcategoryid;
            },
            delId(item) {
                this.dialog_delete = true;
                this.deleteItem.lactivity_id = item.idd;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },
            save() {
                if (this.savedata.ptittle == null) {
                    this.showSnackbar("#b71c1c", "Enter Tittle..");
                } else if (this.savedata.publicationname == null) {
                    this.showSnackbar("#b71c1c", "Enter Name Of Publication..");
                } else if (this.savedata.pdate == null) {
                    this.showSnackbar("#b71c1c", "Select Date..");
                } else if (this.savedata.publicationlevel == null) {
                    this.showSnackbar("#b71c1c", "Select Publication Level..");
                } else if (this.savedata.pcategory == null) {
                    this.showSnackbar("#b71c1c", "Select Publication Category..");
                }else {
                    this.dialog_upload = true;
                    let formData = new FormData();
                    formData.append("tittle", this.savedata.ptittle);
                    formData.append("date_of_publication", this.savedata.pdate);
                    formData.append("name_of_conference_journal", this.savedata.publicationname);
                    formData.append("publicationlevel", this.savedata.publicationlevel);
                    formData.append("publicationcategory", this.savedata.pcategory);
                    formData.append("isbn", this.savedata.isbn);
                    formData.append("publication_link", this.savedata.link);
                    formData.append("file", this.savedata.file);
                    this.dialog_add = false;
                    axios
                        .post("/LearnerData/savelearnerPublication", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then((res) => {
                            if (res.data.msg == "200") {
                                this.dialog_upload = false;
                                this.onLoad();
                                this.showSnackbar("#4caf50", "Publication Added Successfully..."); // show snackbar on success
                                this.savedata.link = null;
                                this.savedata.pcategory = null;
                                this.savedata.isbn = null;
                                this.savedata.publicationname = null;
                                this.savedata.publicationlevel = null;
                                this.savedata.pdate = null;
                                this.savedata.ptittle = null;
                                this.savedata.file = null;
                            } else if (res.data.msg == "NA") {
                                this.dialog_upload = false;
                                this.showSnackbar("#b71c1c", "Publication Already Present!!!");
                            }
                        })
                        .catch((error) => {
                           // window.console.log(error);
                        });

                    this.close();
                }
            }, // end of Save()
            edit() {
                let formData = new FormData();
                formData.append("tittle", this.editedItem.ptittle);
                formData.append("date_of_publication", this.editedItem.pdate);
                formData.append("name_of_conference_journal", this.editedItem.publicationname);
                formData.append("publicationlevel", this.editedItem.publicationlevel);
                formData.append("publicationcategory", this.editedItem.pcategory);
                formData.append("isbn", this.editedItem.isbn);
                formData.append("publication_link", this.editedItem.link);
                formData.append("file", this.editedItem.file);
                formData.append("id", this.editedItem.id);
                this.dialog_edit = false;
                this.dialog_upload = true;
                axios
                    .post("/LearnerData/editlearnerPublication", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.dialog_edit = false;
                            this.onLoad();
                            this.dialog_upload = false;
                            this.showSnackbar("#4caf50", "Publication Updated Successfully..."); // show snackbar on success
                        } else {
                            this.dialog_upload = false;
                            this.showSnackbar("#b71c1c", "Publication Already Present!!!");
                        }
                    })
                    .catch((error) => {
                        //window.console.log(error);
                    });
                this.close();
            }, // end of edit()
            deletedata(item) {
                let formData = new FormData();
                formData.append("id", this.deleteItem.lactivity_id);
                this.dialog_delete = false;
                axios.post("/LearnerData/deletepublication", formData).then((res) => {
                    if (res.data.msg == "200") {
                        if ((this.load = true)) {
                            this.onLoad();
                            this.showSnackbar("#4caf50", " Record Delete Successfully...");
                        }
                    } else {
                        this.showSnackbar("#b71c1c", "Record Not Delete...");
                    }
                });
            },
        },
    };
</script>
<style scoped>
.nav-arrow-left{
        margin-right: 5px;
        text-decoration: none;
        color: #FFF;
    }
    .nav-arrow-right{
        margin: 5px;
        text-decoration: none;
        color: rgb(3, 55, 167);
    }
    .left-arrow{
        color: #FFF;
    }
    .right-arrow{
        color: rgb(3, 55, 167);
    }

    .ec-title{
        background-image: linear-gradient(-100deg, #f4faba, #d64907);
        color: #FFF;
    }
    .c-title{
        background-image: linear-gradient(-90deg, #befcf7, #03948d);
        color: #FFF;
    }
    .tc-title{
        background-image: linear-gradient(-90deg, #FFF, #057996);
        color: #FFF;
        border-radius: 3px;
    }
    .logi{
        background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
        color: #FFF;
    }
    .card-title{
         background-image: linear-gradient(-90deg, #8edffd, #034c70);
        color: #FFF;
    }
    .del-card-title{
         background-image: linear-gradient(-90deg, #fde7e7, #ac0404);
        color: #FFF;
    }
    .lbl{
        color: #1705e6;
    }
    .example {
        margin-top: 0px;
        background: #ffff;
        border-bottom: 1px solid black;
        color: black;
    }
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .alink {
        text-decoration: none;
    }

    .v-icon-size {
        font-size: 20px;
    }

    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }

    .edit-avatar:hover {
        background-color: #b0bec5;
    }

    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }

    .edit-v-icon:hover {
        color: white;
    }
        .required{
    color:red;
    font-size:14px;
    font-style:bold;
    }
</style>
